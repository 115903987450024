:root {
  --primary-color: #251ca2;
  --primary-color-tint: #251ca208;

  --ff-domaine: 'Domaine Sans';
  --ff-basis: 'Basis Grotesque';
  --ff-mono: 'Basis Mono';

  --size-huge: 36px;
  --size-h1: 28px;
  --size-h2: 24px;
  --size-h3: 20px;
  --size-body: 14px;
  --size-intro: 16px;
  --size-label: 14px;
  --size-tiny: 12px;
  --size-table: 12px;
  --size-nav: 14px;

  --table-cell-padding: 5px;
  --article-grid-gap: 20px;
  --article-block-padding: 20px;
  --max-column: 1400px;
}

@media (min-width: 650px) {
  :root {
    --size-huge: 56px;
    --size-h1: 34px;
    --size-h2: 30px;
    --size-h3: 22px;
    --size-body: 16px;
    --size-intro: 20px;
    --size-label: 16px;
    --size-table: 14px;

    --table-cell-padding: 10px;
    --article-grid-gap: 30px;
    --article-block-padding: 40px;
  }
}

@media (min-width: 1200px) {
  :root {
    --size-huge: 68px;
    --size-h1: 42px;
    --size-h2: 36px;
    --size-h3: 28px;
    --size-body: 18px;
    --size-intro: 24px;
    --size-label: 16px;
    --size-table: 16px;

    --table-cell-padding: 10px;
    --article-grid-gap: 40px;
    --article-block-padding: 40px;
  }
}

@media print {
  .hide-on-print {
    display: none;
  }
}

* {
  box-sizing: inherit;
  -webkit-overflow-scrolling: touch;
}

html {
  box-sizing: border-box;
}

body {
  overflow-y: scroll;
  font-family: var(--ff-domaine);
}

figure {
  margin: unset;
}

h1,h2,h3,h4,h5,h6 {
  font-size: unset;
  font-weight: unset;
  margin: unset;
}

a {
  color: inherit;
}
ul {
  margin: unset;
}

main {
  //color: var(--primary-color);
}

main img {
  display: block;
  width: 100%;
}

.reset-button {
  background-color: transparent;
  border: 0;
  padding: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
}

@keyframes blink {
  from {opacity: 0}
  to {opacity: 1}
}

@font-face {
  font-family: var(--ff-domaine);
  src: url('fonts/Domaine Sans Web/DomaineSansTextWeb-Regular.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: var(--ff-basis);
  src: url('fonts/basis-grotesque-medium-web/basis-grotesque-medium.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: var(--ff-mono);
  src: url('fonts/basis-grotesque-mono-web/basis-grotesque-mono.woff') format('woff');
  font-style: normal;
}

img {
  transition: opacity 1s ease-in, transform 0.5s ease-out;
  opacity: 0;
  transform: scale(1.05);
}

img.lazyloaded {
  opacity: 1;
  transform: scale(1)
}

.ff--basis {
  font-family: var(--ff-basis);
}
.ff--domaine {
  font-family: var(--ff-domaine);
}
.font-size--heading,
.heading {
  font-size: var(--size-h1);
  line-height: 1.2;
}
.font-size--body,
.body {
  font-size: var(--size-body);
  line-height: 1.5;
}
.intro-p {
  font-size: var(--size-intro);
}
.font-size--label,
.label {
  font-size: var(--size-label);
}
.heading--huge {
  font-size: var(--size-huge);
}
.heading--1 {
  font-size: var(--size-h2);
}
.heading--2 {
  font-family: var(--ff-basis);
  font-size: var(--size-label);
}
.font-size--tiny {
  font-size: var(--size-tiny);
}

.bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.faded {
  opacity: .6;
}

.text-align--center {
  text-align: center;
}

.Loading-inner {
  opacity: 0;
  text-align: center;
  animation: blink 2s linear 1s infinite alternate;
}

.padding--nav {
   padding: 0 15px 0 20px;
}

.Nav {
  align-items: center;
  background-color: var(--primary-color);
  color: #fff;
  display: flex;
  height: 60px;
  position: sticky;
  top: 0;
  z-index: 4;
}

.Nav-inner {
  display: grid;
  grid-template-columns: auto 1fr auto;
  width: 100%;
}

.Nav-hamburger-container {
  display: flex;
  grid-column: 1;
}

.Nav-title {
  white-space: nowrap;
  padding-left: 1em;
  overflow-x: hidden;
}
.Nav-title,
.Nav .home {
  align-items: center;
  display: flex;
}

.Nav svg {
  fill: #fff;
}

.Nav .hamburger-btn {
  margin-right: 20px;
}

.Nav .nav-right {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.Nav .nav-right .icon--link {
  align-items: center;
  display: flex;
  text-decoration: none;
  color: inherit;
}

.Nav .nav-right .icon--link > svg .icon {
    margin-right: .5rem;
}

.Nav .nav-right .icon--link .divider {
  display: inline-block;
  width: 1px;
  height: 26px;
  background: rgba(255, 255, 255, 0.5);
  margin: 0 35px;
}

.Nav .nav-right .icon--link .fix-btn {
  margin-right: 30px;
}

.Nav .nav-right .icon--link .is-desktop-only {
  display: none;
}

@media (min-width: 768px) {
  .Nav .nav-right .icon--link .is-desktop-only {
    display: block;
  }
}

.Nav .nav-right-item + .nav-right-item {
  margin-left: 2rem;
}

.Nav a {
  text-decoration: none;
}
.Nav a,
.Nav button {
  transition: opacity 100ms ease-out;
}

.Nav a:hover,
.Nav button:hover {
  opacity: 0.6;
}

.Nav-link {
  font-family: var(--ff-basis);
  font-size: var(--size-nav);
}
.Nav-links {
  align-items: center;
  display: none;
  margin-left: auto;
}
@media (min-width: 600px) {
  .Nav-links {
    display: flex;
  }
}
.Nav-links a {
  margin: 0 2em;
}

.Nav a.icon--link {
  line-height: 0;
}

#hamburger-menu {
  position: fixed;
  top: 0; left: 0;
  width: 100%; height: 100vh;
  background: #000;
  color: #fff;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  font-family: "Basis Grotesque";
  -webkit-font-smoothing: antialiased;

  z-index: 11;
  transition: transform 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateX(-100%);
}

#hamburger-menu img {
  opacity: 1;
}

@media (min-width: 768px) {
  #hamburger-menu {
    max-width: 300px;
  }
}

#hamburger-menu .hamburger-content-wrapper {
  padding: 30px 20px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 60px);
}

#hamburger-menu .hamburger-menu-bar {
  width: 100%;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px 0 20px;
  height: 60px;
}

#hamburger-menu .hamburger-menu-bar svg {
  fill: #fff;
}


#hamburger-menu .links-wrapper a {
  display: block;
  font-size: 1.25em;
  color: #fff;
  text-decoration: none;
  margin-top: 0px;
  line-height: 40px;
}

#hamburger-menu .more-info-wrapper {
  font-size: 0.9em;
  line-height: 1.45em;
}

#hamburger-menu .logo-wrapper {
  margin-top: 20px;
  width: 180px;
  padding: 12px 0 0;
}

#hamburger-menu .logo-wrapper img {
  width: 100%;
  height: auto;
  vertical-align: top;
}

#hamburger-menu .logo-wrapper .hoover-logo {
  width: 60%;
  display: inline-block;
}

#hamburger-menu .logo-wrapper .stanford-logo {
  width: 40%;
  display: inline-block;
}

#hamburger-menu .social-wrapper {
  margin-top: 30px;
}

#hamburger-menu .social-wrapper .connect-label {
  vertical-align: top;
  color: #999999;
}

#hamburger-menu .social-wrapper .social-icon {
  width: 20px; height: 20px;
  fill: #fff;
  margin-left: 20px;
}

#hamburger-menu .copyright-wrapper {
  margin-top: 30px;
  color: #999999;
}

#hamburger-menu .additional-links-wrapper a {
  display: block;
  color: #fff;
  text-decoration: none;
}

#hamburger-menu .additional-links-wrapper {
  margin-top: 30px;
}

#hamburger-menu .additional-links-wrapper a {
  color: #999999;
}

#hamburger-menu a:hover {
  opacity: 0.8;
}

.BlogLanding {
  min-height: 100vh;
  padding-bottom: 8em;
}

.BlogLanding-top {
  margin: 0 auto;
  padding: 3em var(--article-grid-gap) 1em;
  max-width: var(--max-column);
  color: var(--primary-color);
}
.BlogLanding-filter {
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  padding: 0;
  margin-top: 15px;
}
.BlogLanding-filter li {
  margin-bottom: .25rem;
}
.BlogLanding-filter--small {
  font-size: 12px;
}
@media (min-width: 600px) {
  .BlogLanding-top {
    padding: 5em var(--article-grid-gap) 2em;
  }
  .BlogLanding-filter {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .BlogLanding-filter li {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
}

.BlogLanding-filter-button {
  color: inherit;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  font-size: inherit;
  padding: 0;
  cursor: pointer;
  text-align: left;
}

.BlogLanding-filter-button:focus {
  outline: 0;
}

.BlogLanding-filter-button--active {
  border-bottom: 1px solid;
}

.ArticleList {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-gap: var(--article-grid-gap);
}

.ArticleList,
#disqus_thread {
  padding: 30px var(--article-grid-gap);
  max-width: var(--max-column);
  margin: 0 auto;
}

.ArticleList-item a {
  display: grid;
  grid-column-gap: var(--article-grid-gap);
  grid-row-gap: 1em;
  text-decoration: none;
}
.ArticleList-item a:hover {
  color: var(--primary-color);
}

.ArticleList-item--large .ArticleList-item-label {
  font-size: var(--size-h1);
}

.ArticleList-item--small a,
.ArticleList-item--medium a,
.ArticleList-item--large a {
  grid-template-areas:
    "meta"
    "label"
    "image"
    "description"
    "preview"
  ;
  grid-template-columns: 1fr;
}

.ArticleList-item-meta {
  grid-area: meta;
}
.ArticleList-item-label {
  grid-area: label;
}
.ArticleList-item-description {
  grid-area: description;
}
.ArticleList-item-image {
  grid-area: image;
}
.ArticleList-item-content {
  grid-area: content;
}
.ArticleList-item-preview {
  grid-area: preview;
}

.ArticleList-item-meta {
  padding-top: 0.5rem;
  border-top: 1px solid var(--primary-color);
}
.ArticleList-item-label {
  font-size: var(--size-h3);
}
.ArticleList-item .Authors {
  margin-top: 1em;
}
.ArticleList-item-divider {
  display: none;
}
@media (min-width: 600px) {
  .ArticleList-item--large a {
    grid-template-areas:
      "meta image"
      "label image"
      "description image"
      "preview image"
    ;
    grid-template-columns: 1fr 1fr;
  }
  .ArticleList-item--medium a,
  .ArticleList-item--small a {
    grid-template-areas:
      "image meta"
      "image label"
      "image description"
    ;
    grid-template-columns: 200px 1fr;
  }
}
@media (min-width: 1100px) {
  .ArticleList {
    grid-template-columns: repeat(6,1fr);
  }
  .ArticleList-item--large {
    grid-column: span 6;
  }
  .ArticleList-item--large a {
    grid-template-rows: auto auto auto 1fr;
  }
  .ArticleList-item--medium {
    grid-column: span 2;
    margin-top: -1em;
  }
  .ArticleList-item--medium a {
    grid-template-areas:
      "image"
      "meta"
      "label"
      "description"
    ;
    grid-template-columns: 1fr;
  }

  .ArticleList-item-divider {
    border-top: 1px solid var(--primary-color);
    display: block;
    grid-column: span 6;
  }
  .ArticleList-item--medium .ArticleList-item-meta {
    border-top: 0;
  }
  .ArticleList-item--small {
    grid-column: span 6;
  }
  .ArticleList-item--small a {
    grid-template-columns: 300px 1fr;
  }
}
@media (min-width: 1500px) {
  .ArticleList-item--large {
    grid-column: span 6;
  }
  .ArticleList-item--medium {
    grid-column: span 2;
  }
}


/**/
.Article {
  //padding-bottom: 6em;
}

.Article header {
  position: relative;
  height: calc(66vh - 60px);
  margin-bottom: calc(-16.67vh - 60px);
}

.Article header img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.Article .heading--2+p {
  margin-top: .25em;
}

.Article p:first-child {
  margin-top: 0;
}

.Article-title-wrap {
  padding-top: 20px;
}

.Article-title-wrap .heading {
  margin-top: 1rem;
}

.Article-read-more {
  margin-bottom: 20px;
}

.ArticleMeta {
  display: flex;
  margin-top: 2rem;
  margin-bottom: 1em;
}

.Article-inner {
  animation: fade-in 1s forwards;
}
.Article-inner .Share {
  grid-area: share;
}
.Article-inner .jsx-parser {
  grid-area: body;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(5vh)
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.Article-inner > * {
  background-color: #fff;
  position: relative;
}

/*.Article-title-wrap {
  background-color: white;
  position: absolute;
  left: 50%;
  bottom: 0;
  padding: 20px;
  transform: translate(-50%,calc(-40vh + 40px));
  z-index: 2;
  width: 100%;
}*/

.text-container,
.Article-title-wrap {
  max-width: 50rem;
}

.video-container,
.iframe-container {
  position: relative;
}
.video-container:after,
.iframe-container:after {
  content: '';
  display: block;
  height: 0;
  padding-bottom: 56.25%;
}

.video-container iframe,
.iframe-container iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}

.video-container,
.chart-container,
.image-container,
.table-container {
  max-width: 1200px;
}

.video-container,
.chart-container,
.image-container,
.text-container,
.table-container,
.Article-title-wrap {
  padding-top: var(--article-block-padding);
  padding-bottom: var(--article-block-padding);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--article-block-padding);
  padding-right: var(--article-block-padding);
}

.video-container iframe,
.iframe-container iframe {
  width: calc(100% - 2*var(--article-block-padding));
}

.chart-container {
  font-size: var(--size-tiny);
  font-family: var(--ff-basis);
}

.table-container {
  font-size: var(--size-table);
  font-family: var(--ff-mono);
  overflow-x: auto;
}

.table-container table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
.table-container th,
.table-container td {
  border: 1px solid;
  padding: var(--table-cell-padding);
  max-width: 12em;
}

.table-container thead tr,
.table-container tbody tr:nth-child(even) {
  background-color: var(--primary-color-tint);
}

.table-container tbody tr:hover td {
  font-weight: bold;
}

.Article .__react_component_tooltip {
  max-width: 400px;
  pointer-events: auto !important;
}

.Article .__react_component_tooltip.show {
  opacity: 1;
}

.Article .footnote {
  font-family: var(--ff-mono);
  font-size: 60%;
  vertical-align: top;
}

.Share {
  display: flex;
  padding: 5px;
}
.Share button {
  background-color: transparent;
  border: 0;
  padding: 0;
}
.Share svg {
  display: block;
  width: 100%;
  height: 100%;
}
.Share > div {
  display: flex;
  justify-content: center;
}

.Share--vertical {
  position: sticky;
  top: 80px;
  display: none;
  flex-direction: column;
  max-height: 100vh;
  width: 30px;
  z-index: 1;
}
.Share--vertical button,
.Share--vertical .SocialMediaShareButton {
  margin-top: .5rem;
}

.Share--horizontal {
  height: 30px;
  flex-direction: row;
  justify-content: center;
}
.Share--horizontal {
  height: 30px;
}
.Share--horizontal svg {
  height: 30px;
  padding: 5px;
  width: auto;
}
.SocialMediaShareButton {
  cursor: pointer;
}
@media (min-width: 900px) {
  .Article-inner .body {
    display: grid;
    grid-template-areas: ". body share";
    grid-template-columns: 1fr auto 1fr;
  }
  .Share--vertical {
    display: flex;
  }
}